import PropTypes from 'prop-types';
import React from 'react';
import { gimmeOne } from '../utilities/utils.js';

const source = {
  genres: [
    'Action',
    'Adventure',
    'Comedy',
    'Comedy of Manners',
    'Parody',
    'Crime',
    'True Crime',
    'Whodunit',
    'Thriller',
    'Murder Mystery',
    'Melodrama',
    'Docudrama',
    'Mockumentary',
    'Detective Story',
    'Fantasy',
    'Fable',
    'Fairy Tale',
    'Buddy Cop',
    'Team Up',
    'Heist',
    'Science Fantasy',
    'Science Fiction',
    'Dying Earth',
    'Biopic',
    'Period Piece',
    'Memoir',
    'Ghost Story',
    'Monster Movie',
    'Occult',
    'Slasher',
    'Porn',
    'Mystery',
    'Space Opera',
    'Western',
    'Steam Punk',
    'Cyberpunk',
    'Love Story',
    'Film Noir',
    'Soap Opera',
    'Road Movie',
    'Buddy',
    'Ensemble Piece',
    'Magical Realism',
  ],

  types: [
    'Super Hero',
    'Romantic',
    'Bromantic',
    'Satiric',
    'Slapstic',
    'Farcical',
    'Courtroom',
    'Gangster',
    'Hard Boiled',
    'Zombie',
    'Dark',
    'Moody',
    'Urban',
    'Folksy',
    'Heroic',
    'Gritty',
    'Mythological',
    'Legendary',
    'Historic',
    'Autobiographical',
    'Alternate History',
    'Magical',
    'Paranoid',
    'Politcal',
    'Utopian',
    'Distopian',
    'Feminist',
    'Speculative',
    'Social',
    'Supernatural',
    'Weird',
    'Quirky',
    'Absurdist',
    'Whimsical',
    'Atomic',
    'Stop Motion',
    'Traditional Animated',
    'VR',
    'Musical',
    'Nostalgic',
    'Alternate Reality',
  ],
  periods: [
    'Distant Future',
    'Near Future',
    'Present Day',
    'Modern Day',
    'Early 20th Century',
    'Mid 20th Century',
    'Late 20th Century',
    '19th Century',
    '18th Century',
    'Medieval',
    'Prehistoric',
  ],
  setting: ['Urban', 'Suburban', 'Rural'],
  generalSetting: [
    'State',
    'Territory',
    'Country',
    'Region',
    'Planet',
    'Plenetary System',
  ],
  hottopics: [
    'Education',
    'Healthcare',
    'Campaign reform',
    'Climate change',
    'Wage disparity',
    'Wealth disrepency',
    'Homelessness',
    'Population growth',
    'Corporal punishment',
    'War',
    'Territorial Conflict',
    'Race Relations',
    'Human Rights',
    'Economic Uncertainty',
    'Human Condition',
    'Existential Angst',
    'Loss of purpose',
    'Freedom',
    'Liberty',
    'Hate Speach',
    'Euthanasia',
    'Domestic Surveilance',
    'Gun Control',
    'Reproductive Rights',
    'Corporate Tax',
    'Welfare',
    'Corporate Welfare',
    'Organized Labour',
    'Imigration',
    'National Security',
  ],
  synonyms: ['Flick', 'Film', 'Movie', 'Trilogy'],
  context: [
    'where the characters are fixed on',
    'where the characters are discuss the finer points about',
    'where the characters move from place to place exploring',
    'but its really about',
  ],
};

// I probably want to keep printing and generating these things.
// So a link to add more.  Storage to hold em.
// And then print them all out.

function GreatMovieIdea({ genre, children }) {
  return (
    <div>
      <h2>{genre}</h2>
      {children}
    </div>
  );
}

GreatMovieIdea.propTypes = {
  children: PropTypes.string,
  genre: PropTypes.string,
};

function makeOneUp() {
  return `A ${gimmeOne(source.types)} ${gimmeOne(source.genres)} ${gimmeOne(
    source.synonyms
  )} set in a ${gimmeOne(source.periods)} ${gimmeOne(
    source.setting
  )} ${gimmeOne(source.generalSetting)}.  ${gimmeOne(
    source.context
  )} ${gimmeOne(source.hottopics)}`;
}

export default function Generator() {
  return (
    <div>
      <GreatMovieIdea>{makeOneUp()}</GreatMovieIdea>
    </div>
  );
}
